
const urlParams = new URLSearchParams(window.location.search);
const interested_v = urlParams.get('int');
document.getElementById("interested").selectedIndex = interested_v;


var sectionsContact = {
  "/": "Página principal",
  "/success-stories/web-agp/": "Casos de éxito/AGP",
  "/success-stories/mobile-sr/": "Casos de éxito/APP Mina",
  "/success-stories/cloud-perufarma/": "Casos de éxito/Peru Farma",
  "/success-stories/cloud-crp/": "Casos de éxito/CRP Radios",
  "/solutions/": "Soluciones",
  "/what-we-do/software-development": "Que hacemos/Desarrollo de software",
  "/what-we-do/cloud": "Que hacemos/Computación en nube",
  "/what-we-do/software-as-a-service/": "Que hacemos/Software as a service",
  "/what-we-do/automation/": "Que hacemos/Automatización",
  "/what-we-do/data-analytics/": "Que hacemos/Análisis de datos",
  "/what-we-do/cibersecurity/": "Que hacemos/Ciberseguridad",
  "/what-we-do/training/": "Que hacemos/Capacitaciones",
  "/what-we-do/licenses/": "Que hacemos/licenciamiento",
  "/contact/": "Contáctanos",
  "/landing/": "Información",
  "/en/": "Página principal",
  "/en/success-stories/web-agp/": "Casos de éxito/AGP",
  "/en/success-stories/mobile-sr/": "Casos de éxito/APP Mina",
  "/en/success-stories/cloud-perufarma/": "Casos de éxito/Peru Farma",
  "/en/success-stories/cloud-crp/": "Casos de éxito/CRP Radios",
  "/en/solutions/": "Soluciones",
  "/en/what-we-do/software-development": "Que hacemos/Desarrollo de software",
  "/en/what-we-do/cloud": "Que hacemos/Computación en nube",
  "/en/what-we-do/software-as-a-service/": "Que hacemos/Software as a service",
  "/en/what-we-do/automation/": "Que hacemos/Automatización",
  "/en/what-we-do/data-analytics/": "Que hacemos/Análisis de datos",
  "/en/what-we-do/cibersecurity/": "Que hacemos/Ciberseguridad",
  "/en/what-we-do/training/": "Que hacemos/Capacitaciones",
  "/en/what-we-do/licenses/": "Que hacemos/licenciamiento",
  "/en/contact/": "Contáctanos",
};

 //----- MODAL --------
 var modalSuccess = new bootstrap.Modal(
    document.getElementById("modal-success")
);
 var closeButtonModal = document.querySelector(
    "#modal-success .modal-success__close-button"
);
closeButtonModal.addEventListener("click", () => {
    clearForm();
    modalSuccess.hide();
});

//----- VALIDAR FORM --------
var clearForm = function() {
  let fields = document.getElementsByClassName("contact-form__field-item");
  for (let field of fields) {
      field.querySelector("input,select,textarea").value = "";
  }
};
var clearFormValidation = function(e) {
  let fieldItems = e.target.getElementsByClassName("contact-form__field-item");
  for (let fieldItem of fieldItems) {
      fieldItem.classList.remove("error");
      if(fieldItem.getElementsByClassName("error")[0])
      {
        fieldItem.getElementsByClassName("error")[0].remove();//fieldItem.getElementsByClassName("error")[0] ? .remove();
      }
  }
};
var addFormValidation = function(objectValidation, e) {
  clearFormValidation(e);
  let keysObjectValidation = Object.keys(objectValidation);
  for (let keyValidation of keysObjectValidation) {
      let fieldItem = e.target.getElementsByClassName(keyValidation)[0];
      fieldItem.classList.add("error");
      let newSpan = document.createElement("span");
      newSpan.className = "error";
      let newText = document.createTextNode(objectValidation[keyValidation][0]);
      newSpan.appendChild(newText);
      fieldItem.appendChild(newSpan);
  }
};

var validForm = function(name, email, phone, company, interested, comment, e) {
  let language = document.documentElement.lang;
  let validationSchema = function(language) {
      return {
          name: {
              presence: {
                  allowEmpty: false,
                  message: language === "es" ? "Nombre es requerido" : "Name is required",
              },
              format: {
                  pattern: /^([A-Za-z\s]*)$/,
                  message: language === "es" ?
                      "Ingrese un nombre valido" : "Enter a valid name",
              },
          },
          email: {
              presence: {
                  allowEmpty: false,
                  message: language === "es" ? "Email es requerido" : "Email is required",
              },
              email: {
                  message: language === "es" ?
                      "Ingrese un email valido" : "Enter a valid email",
              },
          },
          phone: {
              presence: {
                  allowEmpty: false,
                  message: language === "es" ?
                      "Número de teléfono es requerido" : "Phone number is required",
              },
              format: {
                  pattern: /^\+*[1-9][0-9]+$/i,
                  message: language === "es" ?
                      "Ingrese un número telefono valido" : "Enter a valid phone number",
              },
              length: {
                  maximum: 13,
                  message: language === "es" ?
                      "Debe ser menor a 13 digitos" : "Must be less than 13 digits",
              },
          },
          company: {
              presence: {
                  allowEmpty: false,
                  message: language === "es" ?
                      "Organización es requerido" : "Organization is required",
              },
              format: {
                  pattern: /^([A-Za-z\s]*)$/,
                  message: language === "es" ?
                      "Ingrese una organización valida" : "Enter a valid organization",
              },
          },
          interested: {
              presence: {
                  allowEmpty: false,
                  message: language === "es" ? "Interes es requerido" : "Interest is required",
              },
          },

          ...(interested === "Otros" ? {
              comment: {
                  presence: {
                      allowEmpty: false,
                      message: language === "es" ?
                          "Comentario es requerido" : "Comment is required",
                  },
              },
          } : {}),
      };
  };

  let objValues = { name, email, phone, company, interested, comment };
  clearFormValidation(e);

  let validateResultObject = validate(objValues, validationSchema(language), {
      fullMessages: false,
  });

  if (!validateResultObject) {
      return true;
  } else {
      addFormValidation(validateResultObject, e);
      return false;
  }
};


//FORM SUBMIT----------------------------------------------------------------------------
var contactForm = document.getElementsByClassName("contact-form");

for (let i = 0; i < contactForm.length; i++) {
    contactForm[i].addEventListener("submit", (e) => {
        e.preventDefault();
        const url = e.target.action;
        const nombres = e.target["nombres"].value;
        const email = e.target["email"].value;
        const phone = e.target["phone"].value;
        const company = e.target["company"].value;
        const interested = e.target["interested"].value;
        const comment = e.target["comment"].value;

        if (validForm(nombres, email, phone, company, interested, comment, e)) {
            sendEmail({
                url,
                nombres,
                email,
                phone,
                company,
                interested,
                comment,
            });
            modalSuccess.show();
        }
    });
};


//----------------------------------------------------------------------------

function getKeySection(cadena) {
    if (
        cadena === "" ||
        cadena === "/" ||
        cadena === "index.html" ||
        cadena === "/index.html"
    ) {
        return "/";
    }
    var keysSectionNav = Object.keys(sectionsContact).reverse();
    return keysSectionNav.find((key) => {
        if (key === cadena) {
            return true;
        } else {
            var regExpKey = new RegExp(key);
            var regExpCadena = new RegExp(cadena);
            if (regExpKey.test(cadena)) {
                return true;
            } else if (regExpCadena.test(key)) {
                return true;
            } else {
                return false;
            }
        }
    });
}

function sendEmail({ url, nombres, email, phone, company, interested, comment }) {
    if (!axios) return;

    //* función para enviar el email
    const language =
        window.location.pathname.split("/")[1] === "en" ? "en" : "es";
    const href = window.location.href;
    const keySection = getKeySection(window.location.pathname);
    let form = JSON.stringify({
        nombres,
        email,
        phone,
        interested,
        comment,
        section: sectionsContact[keySection],
        url: href,
        company: company,
        type: 1,
        language,
    });

    console.log({
        nombres,
        email,
        phone,
        interested,
        comment,
        section: sectionsContact[keySection],
        url: href,
        company: company,
        type: 1,
        language,
    });

    axios
        .post(url, form, {
            headers: {
                "content-type": "application/json",
            },
        })
        .then((response) => console.log(response))
        .catch((error) => console.log(error.response));
}

